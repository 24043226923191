import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const props = {
  value: PropTypes.number,
  showPrefix: PropTypes.bool,
  currency: PropTypes.string
};

class NumberDisplay extends React.Component {
  static propTypes = props;

  render() {
    const { value, displayType, showPrefix, currency, decimalScale } = this.props;
    let cur = {
      thousandSeparator: '.',
      decimalSeparator: ',',
      symbol: '$ ',
      decimalScale: decimalScale || 0,
      prefix: currency
    };

    return (
    <>
      {showPrefix && currency ? cur.prefix + ' ' : ''}
      <NumberFormat className={value && value.toString().length >= 7 ? 'long-value' : ''} value={value || 0} displayType={displayType || 'text'} thousandSeparator={cur.thousandSeparator} decimalSeparator={cur.decimalSeparator} prefix={cur.symbol} decimalScale={cur.decimalScale} fixedDecimalScale />
    </>
    );
  }
}

export default NumberDisplay;