import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { listIatas } from '../../services/api';
import * as yup from 'yup';
import { debounce } from './../../utils/debouncer';

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const props = {
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func
};

class LocationForm extends React.Component {
  static propTypes = props;

  constructor(props) {
    const { t } = props;
    super(props);

    this.state = {
      iataCodes: [],
      countryCodes: [],
      cities: [],
      modal: this.props.modal || false,
      fields: {
        name: '',
        selectedIataCodeInput: '',
        selectedIataCodeValue: null,
        selectedCountryCodeInput: '',
        selectedCountryCodeValue: null,
        selectedCityInput: '',
        selectedCityValue: null
      },
      formErrors: {
        name: '',
        selectedIataCodeInput: '',
        selectedIataCodeValue: '',
        selectedCountryCodeInput: '',
        selectedCountryCodeValue: '',
        selectedCityInput: '',
        selectedCityValue: ''
      }
    };

    this.formSchema = yup.object().shape({
      name: yup
        .string()
        .required(t('inputIsRequired', { field: 'Nombre' })),
      selectedIataCodeInput: yup
        .string()
        .required(
          t('inputIsRequired', { field: 'Código IATA' }),
        ),
      selectedCountryCodeInput: yup
        .string()
        .required(
          t('inputIsRequired', { field: 'Código País' }),
        ),
      selectedCityInput: yup
        .string()
        .required(
          t('inputIsRequired', { field: 'Ciudad' }),
        )
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal && this.props.modal) {
      const currentLocations = this.props.locations;
      const countryCodes = [...new Set(currentLocations.map(l => l.countryCode))];
      const cities = [...new Set(currentLocations.map(l => l.city))];

      this.setState({ countryCodes, cities });
    }
  };

  getIatasByCode = debounce(async (iataCode) => {
    let iataCodes = [];
    const resp = await listIatas(iataCode);
  
    if (resp.status === 200 && resp.data)
      iataCodes = resp.data

    this.setState({ iataCodes });
  }, 500);

  onIataCodeChange = (option) => {
    const fields = {...this.state.fields, ...{
      selectedIataCodeValue: option,
      selectedCityInput: option ? option.city : '',
      selectedCountryCodeInput: option ? option.countryCode : '',
      name: option ? option.name : ''
    }};

    const formErrors = {...this.state.formErrors, ...{
      name: '',
      selectedIataCodeInput: '',
      selectedIataCodeValue: '',
      selectedCountryCodeInput: '',
      selectedCountryCodeValue: '',
      selectedCityInput: '',
      selectedCityValue: ''
    }};

    this.setState({ fields, formErrors });
  };

  onIataCodeInputChange = codeString => {
    let iataCodes = [];

    if (codeString.length > 1)
      iataCodes = this.getIatasByCode(codeString);

    const fields = { ...this.state.fields, ...{ selectedIataCodeInput: codeString } };
    const formErrors = { ...this.state.formErrors, ...{ selectedIataCodeInput: '' } }
    this.setState({ iataCodes, fields, formErrors });
  };


  getIataOptionLabel = (option) => {
    return `[${option.code}] ${option.countryCode} - ${option.city} - ${option.name}`;
  };

  onCountryCodeChange = (option) => {
    const fields = {...this.state.fields, ...{ selectedCountryCodeValue: option }};
    this.setState({ fields });
  };

  onCountryCodeInputChange = (newValue) => {
    const fields = { ...this.state.fields, ...{ selectedCountryCodeInput: newValue } };
    const formErrors = { ...this.state.formErrors, ...{ selectedCountryCodeInput: '' } }
    this.setState({ fields, formErrors });
  };

  onCityChange = (option) => {
    const fields = {...this.state.fields, ...{ selectedCityValue: option }};
    this.setState({ fields });
  };

  onCityInputChange = (newValue) => {
    const fields = { ...this.state.fields, ...{ selectedCityInput: newValue } };
    const formErrors = { ...this.state.formErrors, ...{ selectedCityInput: '' } }
    this.setState({ fields, formErrors });
  };

  onInputChange = (event) => {
    const { name, value } = event.target;
    const fields = { ...this.state.fields, ...{ [name]: value } };
    const formErrors = { ...this.state.formErrors, ...{ [name]: '' } }
    this.setState({ fields, formErrors });
  };

  submit = () => {
    this.formSchema
      .validate(this.state.fields, { abortEarly: false })
      .then(valid => {
        const { fields } = this.state;
        let iataCode = fields.selectedIataCodeInput;

        if (iataCode.indexOf(']') !== -1)
          iataCode = iataCode.substring(0, iataCode.indexOf(']')).replace('[', '');

        const newLocationObj = {
          IATA: iataCode,
          CountryCode: fields.selectedCountryCodeInput,
          City: fields.selectedCityInput,
          Name: fields.name
        };

        this.clearComponentState();
        this.props.onModalSubmit(newLocationObj);
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        this.setState(prevState => ({ ...prevState, formErrors, saving: false }));
      });
  };

  onModalClose = () => {
    this.clearComponentState();
    this.props.onModalClose();
  };

  clearComponentState = () => {
    this.setState({
      iataCodes: [],
      countryCodes: [],
      cities: [],
      modal: this.props.modal || false,
      fields: {
        name: '',
        selectedIataCodeInput: '',
        selectedIataCodeValue: null,
        selectedCountryCodeInput: '',
        selectedCountryCodeValue: null,
        selectedCityInput: '',
        selectedCityValue: null
      },
      formErrors: {
        name: '',
        selectedIataCodeInput: '',
        selectedIataCodeValue: '',
        selectedCountryCodeInput: '',
        selectedCountryCodeValue: '',
        selectedCityInput: '',
        selectedCityValue: ''
      }
    });
  };

  render() {
    const { fields, formErrors } = this.state;

    return (
      <Dialog open={this.props.modal} onClose={this.onModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Nuevo Location
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            id="selectedIataCodeId"
            name="selectedIataCodeId"
            options={this.state.iataCodes || []}
            style={{ width: 500 }}
            value={fields.selectedIataCodeValue}
            onChange={(e, newValue) => this.onIataCodeChange(newValue)}
            inputValue={fields.selectedIataCodeInput}
            getOptionLabel={(option) => this.getIataOptionLabel(option)}
            onInputChange={(e, newValue) => this.onIataCodeInputChange(newValue)}
            renderInput={params => (
              <TextField
                error={formErrors.selectedIataCodeInput ? true : false}
                helperText={formErrors.selectedIataCodeInput}
                {...params}
                label="Código IATA"
                variant="outlined"
              />
            )}
          />
          <br />
          <Autocomplete
            freeSolo
            id="selectedCountryCodeInputId"
            name="selectedCountryCodeInputId"
            options={this.state.countryCodes}
            style={{ width: 500 }}
            value={fields.selectedCountryCodeValue}
            onChange={(e, newValue) => this.onCountryCodeChange(newValue)}
            inputValue={fields.selectedCountryCodeInput}
            onInputChange={(e, newValue) => this.onCountryCodeInputChange(newValue)}
            renderInput={params => (
              <TextField
                error={formErrors.selectedCountryCodeInput ? true : false}
                helperText={formErrors.selectedCountryCodeInput}
                {...params}
                label="Código País"
                variant="outlined"
              />
            )}
          />
          <br />
          <Autocomplete
            freeSolo
            id="selectedCityInputId"
            name="selectedCityInputId"
            options={this.state.cities}
            style={{ width: 500 }}
            value={fields.selectedCityValue}
            onChange={(e, newValue) => this.onCityChange(newValue)}
            inputValue={fields.selectedCityInput}
            onInputChange={(e, newValue) => this.onCityInputChange(newValue)}
            renderInput={params => (
              <TextField
                error={formErrors.selectedCityInput ? true : false}
                helperText={formErrors.selectedCityInput}
                {...params}
                label="Ciudad"
                variant="outlined"
              />
            )}
          />
          <br />
          <TextField
            margin="dense"
            id="name"
            name="name"
            label={"Nombre"}
            type="text"
            fullWidth
            value={fields.name}
            onChange={e => this.onInputChange(e)}
            helperText={formErrors.name}
            error={formErrors.name ? true : false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onModalClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(LocationForm);