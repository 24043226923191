import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import * as yup from 'yup';

const props = {
  tenantId: PropTypes.number,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func,
};

class AdditionalForm extends React.Component {
  static propTypes = props;

  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      additional: {
        name: '',
        type: '',
        description: '',
        imagePath: '',
      },
      isLoading: true,
      saving: false,
      modal: this.props.modal,
      formErrors: {
        name: '',
        type: '',
        description: '',
        imagePath: '',
      },
    };

    this.formSchema = yup.object().shape({
      name: yup.string().required(t('inputIsRequired', { field: 'Nombre' })),
      type: yup.string().required(t('inputIsRequired', { field: 'Tipo' })),
      description: yup
        .string()
        .required(t('inputIsRequired', { field: 'Descripción' })),
      imagePath: yup
        .string()
        .url('Formato inválido')
        .required(t('inputIsRequired', { field: 'Logo' })),
    });
  }

  async componentDidUpdate(prevProps) {
    const { additional, additionalId } = this.props;

    if (additionalId === prevProps.additionalId) return;

    if (additional) {
      this.setState(state => ({
        additional: {
          ...state.additional,
          id: additional.id,
          name: additional.name,
          type: additional.type,
          description: additional.description,
          imagePath: additional.imagePath,
        },
      }));
    } else {
      const additional = {
        name: '',
        type: '',
        description: '',
        imagePath: '',
      };
      this.setState({ additional });
    }
  }

  onInputChange = event => {
    const { name, value } = event.target;
    const additional = { ...this.state.additional, ...{ [name]: value } };
    const formErrors = { ...this.state.formErrors, ...{ [name]: '' } };
    this.setState({ additional, formErrors });
  };

  submit = () => {
    this.setState({ saving: true });

    this.formSchema
      .validate(this.state.additional, { abortEarly: false })
      .then(valid => {
        this.props.onModalSubmit(this.state.additional);
        this.clearComponentState();
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        this.setState(prevState => ({
          ...prevState,
          formErrors,
          saving: false,
        }));
      });
  };

  clearComponentState = () => {
    this.setState({
      additional: {
        name: '',
        type: '',
        description: '',
        imagePath: '',
      },
      isLoading: true,
      saving: false,
      formErrors: {
        name: '',
        type: '',
        description: '',
        imagePath: '',
      },
    });
  };

  onModalClose = () => {
    this.clearComponentState();
    this.props.onModalClose();
  };

  render() {
    const { additionalId } = this.props;
    const { additional, formErrors } = this.state;

    return (
      <Dialog
        open={this.props.modal}
        onClose={this.onModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {additionalId ? 'Editar Adicional' : 'Nuevo Adicional'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label={'Nombre del Adicional'}
            type="text"
            fullWidth
            onChange={e => this.onInputChange(e)}
            value={additional.name}
            helperText={formErrors.name}
            error={formErrors.name ? true : false}
            required
          />
          <TextField
            id="type"
            name="type"
            label={'Tipo'}
            type="text"
            fullWidth
            onChange={e => this.onInputChange(e)}
            value={additional.type}
            helperText={formErrors.type}
            error={formErrors.type ? true : false}
            required
          />
          <TextField
            margin="dense"
            id="description"
            name="description"
            label={'Descripción'}
            type="text"
            fullWidth
            onChange={e => this.onInputChange(e)}
            value={additional.description}
            helperText={formErrors.description}
            error={formErrors.description ? true : false}
            required
          />
          <TextField
            margin="dense"
            id="imagePath"
            name="imagePath"
            label={'URL Logo'}
            type="imagePath"
            fullWidth
            onChange={e => this.onInputChange(e)}
            value={additional.imagePath}
            helperText={formErrors.imagePath}
            error={formErrors.imagePath ? true : false}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onModalClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(AdditionalForm);
