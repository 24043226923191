import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getTenant } from '../../services/api';
import { StyledContainer } from './TenantForm.styled';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
} from '@material-ui/core';
import * as yup from 'yup';

const props = {
  tenantId: PropTypes.number,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func,
};

class TenantForm extends React.Component {
  static propTypes = props;

  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      tenant: {
        name: '',
        baseUrl: '',
        userName: '',
        password: '',
        logoPath: '',
        commission: 0.01,
        brandCode: '',
        applyCommissionToAdditionals: true,
        applyCommissionToTaxes: false,
        applyCommissionToInsurances: true,
        termsAndConditions: '',
        noShowCharge: 0,
        privacyPolicy: '',
        prepaidCommercialAgreement: '',
        payOnDestinationCommercialAgreement: '',
        prepaidZeroExcessCommercialAgreement: '',
        payOnDestinationZeroExcessCommercialAgreement: '',
        disclaimer: '',
        minAgeWithoutDriverFee: 0,
        maxAgeWithoutDriverFee: 0,
        minAgeToDrive: 0,
        maxAgeToDrive: 0,
        forcePasswordValidation: true,
      },
      isLoading: true,
      saving: false,
      modal: this.props.modal,
      formErrors: {
        name: '',
        baseUrl: '',
        brandCode: '',
        userName: '',
        password: '',
        logoPath: '',
        commission: '',
        applyCommissionToAdditionals: '',
        applyCommissionToTaxes: '',
        applyCommissionToInsurances: '',
        termsAndConditions: '',
        noShowCharge: '',
        privacyPolicy: '',
        prepaidCommercialAgreement: '',
        payOnDestinationCommercialAgreement: '',
        prepaidZeroExcessCommercialAgreement: '',
        payOnDestinationZeroExcessCommercialAgreement: '',
        disclaimer: '',
        minAgeWithoutDriverFee: '',
        maxAgeWithoutDriverFee: '',
        minAgeToDrive: '',
        maxAgeToDrive: '',
      },
    };

    this.formSchema = yup.object().shape({
      name: yup.string().required(t('inputIsRequired', { field: 'Nombre' })),
      baseUrl: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Formato inválido',
        )
        .required(t('inputIsRequired', { field: 'Base URL' })),
      userName: yup
        .string()
        .required(t('inputIsRequired', { field: 'Nombre de usuario' })),
      forcePasswordValidation: yup.boolean(),
      password: yup.string().when('forcePasswordValidation', {
        is: true,
        then: yup
          .string()
          .required(t('inputIsRequired', { field: 'Contraseña' })),
      }),
      minAgeToDrive: yup.number(),
      maxAgeToDrive: yup.number(),
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.tenantId === prevProps.tenantId) return;

    if (this.props.tenantId) {
      const tenantResponse = await getTenant(this.props.tenantId);

      if (tenantResponse.status === 200 && tenantResponse.data) {
        let tenant = tenantResponse.data;
        tenant['forcePasswordValidation'] = false;
        delete tenant['password'];
        this.setState({ tenant });
      }
    } else {
      const tenant = {
        name: '',
        baseUrl: '',
        userName: '',
        password: '',
        logoPath: '',
        brandCode: '',
        commission: 0.01,
        applyCommissionToAdditionals: true,
        applyCommissionToTaxes: false,
        applyCommissionToInsurances: true,
        termsAndConditions: '',
        noShowCharge: 0,
        privacyPolicy: '',
        prepaidCommercialAgreement: '',
        payOnDestinationCommercialAgreement: '',
        prepaidZeroExcessCommercialAgreement: '',
        payOnDestinationZeroExcessCommercialAgreement: '',
        disclaimer: '',
        minAgeWithoutDriverFee: 0,
        maxAgeWithoutDriverFee: 0,
        minAgeToDrive: 0,
        maxAgeToDrive: 0,
        forcePasswordValidation: true,
      };
      this.setState({ tenant });
    }
  }

  onInputChange = event => {
    const { name, value } = event.target;
    const tenant = { ...this.state.tenant, ...{ [name]: value } };
    const formErrors = { ...this.state.formErrors, ...{ [name]: '' } };
    this.setState({ tenant, formErrors });
  };

  onCheckBoxChange = event => {
    const { name, checked } = event.target;
    const tenant = { ...this.state.tenant, ...{ [name]: checked } };
    this.setState({ tenant });
  };

  submit = () => {
    this.setState({ saving: true });

    this.formSchema
      .validate(this.state.tenant, { abortEarly: false })
      .then(valid => {
        this.props.onModalSubmit(this.state.tenant);
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        this.setState(prevState => ({
          ...prevState,
          formErrors,
          saving: false,
        }));
      });
  };

  render() {
    const { tenant, formErrors } = this.state;

    return (
      <Dialog
        open={this.props.modal}
        onClose={this.props.onModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.tenantId ? 'Editar Tenant' : 'Nuevo Tenant'}
        </DialogTitle>
        <DialogContent>
          <StyledContainer>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label={'Nombre del Tenant'}
              type="text"
              fullWidth
              onChange={e => this.onInputChange(e)}
              value={tenant.name}
              helperText={formErrors.name}
              required
            />
            <TextField
              id="baseUrl"
              name="baseUrl"
              label={'URL Base'}
              type="text"
              fullWidth
              onChange={e => this.onInputChange(e)}
              value={tenant.baseUrl}
              helperText={formErrors.baseUrl}
            />
            <TextField
              margin="dense"
              id="userName"
              name="userName"
              label={'Nombre de usuario'}
              type="text"
              fullWidth
              onChange={e => this.onInputChange(e)}
              value={tenant.userName}
              helperText={formErrors.userName}
              required
            />
            {!tenant.id && (
              <TextField
                margin="dense"
                id="password"
                name="password"
                label={'Contraseña'}
                type="password"
                fullWidth
                onChange={e => this.onInputChange(e)}
                value={tenant.password}
                helperText={formErrors.password}
                required
              />
            )}
            <TextField
              margin="dense"
              id="logoPath"
              name="logoPath"
              label={'URL Logo'}
              type="logoPath"
              fullWidth
              onChange={e => this.onInputChange(e)}
              value={tenant.logoPath || ''}
              helperText={formErrors.logoPath}
            />
            <Box className="HalfInputsContainer">
              <TextField
                margin="dense"
                id="commission"
                name="commission"
                label={'Comisión'}
                type="commission"
                onChange={e => this.onInputChange(e)}
                value={tenant.commission}
                helperText={formErrors.commission}
              />
              <TextField
                margin="dense"
                id="noShowCharge"
                name="noShowCharge"
                label={'Cargo por no-show'}
                type="noShowCharge"
                onChange={e => this.onInputChange(e)}
                value={tenant.noShowCharge}
                helperText={formErrors.noShowCharge}
              />
              <TextField
                margin="dense"
                id="minAgeWithoutDriverFee"
                name="minAgeWithoutDriverFee"
                label={'minAgeWithoutDriverFee'}
                type="minAgeWithoutDriverFee"
                onChange={e => this.onInputChange(e)}
                value={tenant.minAgeWithoutDriverFee}
                helperText={formErrors.minAgeWithoutDriverFee}
              />
              <TextField
                margin="dense"
                id="maxAgeWithoutDriverFee"
                name="maxAgeWithoutDriverFee"
                label={'maxAgeWithoutDriverFee'}
                type="maxAgeWithoutDriverFee"
                onChange={e => this.onInputChange(e)}
                value={tenant.maxAgeWithoutDriverFee}
                helperText={formErrors.maxAgeWithoutDriverFee}
              />
              <TextField
                margin="dense"
                id="minAgeToDrive"
                name="minAgeToDrive"
                label={'minAgeToDrive'}
                type="minAgeToDrive"
                onChange={e => this.onInputChange(e)}
                value={tenant.minAgeToDrive}
                helperText={formErrors.minAgeToDrive}
              />
              <TextField
                margin="dense"
                id="maxAgeToDrive"
                name="maxAgeToDrive"
                label={'maxAgeToDrive'}
                type="maxAgeToDrive"
                onChange={e => this.onInputChange(e)}
                value={tenant.maxAgeToDrive}
                helperText={formErrors.maxAgeToDrive}
              />

              <TextField
                margin="dense"
                id="marca"
                name="brandCode"
                label={'Marca'}
                type="marca"
                onChange={e => this.onInputChange(e)}
                value={tenant.brandCode}
                helperText={formErrors.brandCode}
              />

              <TextField
                margin="dense"
                id="prepaidCommercialAgreement"
                name="prepaidCommercialAgreement"
                label={'Prepaid Commercial Agreement'}
                type="prepaidCommercialAgreement"
                onChange={e => this.onInputChange(e)}
                value={tenant.prepaidCommercialAgreement}
                helperText={formErrors.prepaidCommercialAgreement}
              />
              <TextField
                margin="dense"
                id="payOnDestinationCommercialAgreement"
                name="payOnDestinationCommercialAgreement"
                label={'Pay on destination Commercial Agreement'}
                type="payOnDestinationCommercialAgreement"
                onChange={e => this.onInputChange(e)}
                value={tenant.payOnDestinationCommercialAgreement}
                helperText={formErrors.payOnDestinationCommercialAgreement}
              />
              <TextField
                margin="dense"
                id="prepaidZeroExcessCommercialAgreement"
                name="prepaidZeroExcessCommercialAgreement"
                label={'Prepaid Zero Excess Commercial Agreement'}
                type="prepaidZeroExcessCommercialAgreement"
                fullWidth
                onChange={e => this.onInputChange(e)}
                value={tenant.prepaidZeroExcessCommercialAgreement}
                helperText={formErrors.prepaidZeroExcessCommercialAgreement}
              />
              <TextField
                margin="dense"
                id="payOnDestinationZeroExcessCommercialAgreement"
                name="payOnDestinationZeroExcessCommercialAgreement"
                label={'Pay On Destination Zero Excess Commercial Agreement'}
                type="payOnDestinationZeroExcessCommercialAgreement"
                fullWidth
                onChange={e => this.onInputChange(e)}
                value={tenant.payOnDestinationZeroExcessCommercialAgreement}
                helperText={
                  formErrors.payOnDestinationZeroExcessCommercialAgreement
                }
              />
            </Box>
            <TextField
              margin="dense"
              id="disclaimer"
              name="disclaimer"
              label={'Disclaimer'}
              type="disclaimer"
              fullWidth
              onChange={e => this.onInputChange(e)}
              value={tenant.disclaimer || ''}
              helperText={formErrors.disclaimer}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="applyCommissionToAdditionals"
                  color="primary"
                  checked={tenant.applyCommissionToAdditionals}
                  onChange={e => this.onCheckBoxChange(e)}
                />
              }
              label="Aplicar comisión a adicionales"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="applyCommissionToTaxes"
                  color="primary"
                  checked={tenant.applyCommissionToTaxes}
                  onChange={e => this.onCheckBoxChange(e)}
                />
              }
              label="Aplicar comisión a impuestos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="applyCommissionToInsurances"
                  color="primary"
                  checked={tenant.applyCommissionToInsurances}
                  onChange={e => this.onCheckBoxChange(e)}
                />
              }
              label="Aplicar comisión a seguros"
            />
            <Divider />
            <br />
            <FormControlLabel
              control={
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Términos y condiciones"
                  value={tenant.termsAndConditions || ''}
                  name="termsAndConditions"
                  onChange={e => this.onInputChange(e)}
                  style={{ width: '100%' }}
                />
              }
              label="Términos y condiciones"
              labelPlacement="top"
            />
            <br />
            <FormControlLabel
              control={
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Política de privacidad"
                  value={tenant.privacyPolicy || ''}
                  name="privacyPolicy"
                  onChange={e => this.onInputChange(e)}
                  style={{ width: '100%' }}
                />
              }
              label="Política de privacidad"
              labelPlacement="top"
            />
          </StyledContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onModalClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(TenantForm);
