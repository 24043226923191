import styled from 'styled-components';

const commonTitleStyles = `
  padding: 0;
  box-sizing: border-box
  text-transform: none;
`;

const getFontSize = (fontSize, defaultSize) =>
  `font-size: ${(fontSize && `${fontSize}px`) || defaultSize} !important;`;

const getTitleMediaQuerys = theme => `
  @media (max-width: ${theme.screens.md}) {
    ${getFontSize(null, '21px')}
  }

  @media (min-width: ${theme.screens.md}) and (max-width: ${theme.screens.lg}){
    ${props => getFontSize(props.fontSize, '18px')}
  }

  @media (min-width: ${theme.screens.lg}) and (max-width: ${theme.screens.xl}){
    ${props => getFontSize(props.fontSize, '25px')}
  }

  @media (min-width: ${theme.screens.xl}) and (max-width: ${theme.screens.xxl}){
    ${props => getFontSize(props.fontSize, '25px')}
  }
`;

export const StyledTitle = styled.div`
  color: ${props => props.color || props.theme.font.colors.primary.main};
  
  h1 {
    margin-top: 20px;
  }

  h2 {
  }

  h3 {
    margin-bottom: 10px !important;
    text-align: center;
    font-size: 1.5rem;
  }

  h4 {}

  h5 {}

  h6 {}

  h1,  h2,  h3,  h4,  h5,  h6 {
    ${commonTitleStyles}

    ${props => props.bold && `font-weight: bold;`}

    ${props => props.italic && `font-style: normal;`}

    ${props => props.weight && `font-weight: ${props.weight};`}

    ${props => getFontSize(props.fontSize, '30px')}
    
    ${props => !props.noMedia && getTitleMediaQuerys(props.theme)}

    ${props => props.lineHeight}

    ${props => props.margin && `margin: ${props.margin}`}
  }
`;
