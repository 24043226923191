import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { listAdditionals, getRentlyAdditionals } from '../../services/api';
import * as yup from 'yup';

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const props = {
  tenantId: PropTypes.string,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func
};

class AdditionalMatchForm extends React.Component {
  static propTypes = props;

  constructor(props) {
    const { t } = props;
    super(props);

    this.state = {
      additionals: [],
      tenantAdditionals: [],
      modal: this.props.modal || false,
      fields: {
        selectedAdditionalInput: '',
        selectedAdditionalValue: null,
        selectedTenantAdditionalInput: '',
        selectedTenantAdditionalValue: null,
      },
      formErrors: {
        selectedAdditionalInput: '',
        selectedAdditionalValue: '',
        selectedTenantAdditionalInput: '',
        selectedTenantAdditionalValue: '',
      }
    };

    this.formSchema = yup.object().shape({
      selectedAdditionalInput: yup
        .string()
        .required(
          t('inputIsRequired', { field: 'Adicional' }),
        ),
				selectedTenantAdditionalInput: yup
        .string()
        .required(
          t('inputIsRequired', { field: 'Adicional del tenant' }),
        )
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal && this.props.modal) {
      const additionalsResp = await listAdditionals(this.props.tenantId);
      const tenantAdditionalsResp = await getRentlyAdditionals(this.props.tenantId);
      let additionals, tenantAdditionals = [];

      if (additionalsResp.status === 200 && additionalsResp.data)
        additionals = additionalsResp.data;

      if (tenantAdditionalsResp.status === 200 && tenantAdditionalsResp.data)
        tenantAdditionals = tenantAdditionalsResp.data;

      this.setState({ additionals, tenantAdditionals, loading: false });
    }
  };

  getAdditionalsOptionLabel = (option) => {
    return `[${option.id}] (${option.type}) - ${option.name}`;
  };

  getTenantAdditionalsOptionLabel = (option) => {
    return `[${option.id}] (${option.type}) - ${option.name} - ${option.description}`;
  };

  onAdditionalChange = (option) => {
    const fields = {...this.state.fields, ...{ selectedAdditionalValue: option }};
    this.setState({ fields });
  };

  onAdditionalInputChange = (newValue) => {
    const fields = { ...this.state.fields, ...{ selectedAdditionalInput: newValue } };
    const formErrors = { ...this.state.formErrors, ...{ selectedAdditionalInput: '' } }
    this.setState({ fields, formErrors });
  };

  onTenantAdditionalChange = (option) => {
    const fields = {...this.state.fields, ...{ selectedTenantAdditionalValue: option }};
    this.setState({ fields });
  };

  onLocationInputChange = (newValue) => {
    const fields = { ...this.state.fields, ...{ selectedTenantAdditionalInput: newValue } };
    const formErrors = { ...this.state.formErrors, ...{ selectedTenantAdditionalInput: '' } }
    this.setState({ fields, formErrors });
  };

  submit = () => {
    this.formSchema
      .validate(this.state.fields, { abortEarly: false })
      .then(valid => {
        const { fields } = this.state;
        const newAdditionalMatchObj = {
					additionalId: fields.selectedAdditionalValue.id,
					tenantAdditionalId: fields.selectedTenantAdditionalValue.id
        };
    
        this.clearComponentState();
        this.props.onModalSubmit(newAdditionalMatchObj);
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        this.setState(prevState => ({ ...prevState, formErrors, saving: false }));
      });
  };

  onModalClose = () => {
    this.clearComponentState();
    this.props.onModalClose();
  };

  clearComponentState = () => {
    this.setState({
      tenants: [],
      places: [],
      locations: [],
			fields: {
        selectedAdditionalInput: '',
        selectedAdditionalValue: null,
        selectedTenantAdditionalInput: '',
        selectedTenantAdditionalValue: null,
      },
      formErrors: {
        selectedAdditionalInput: '',
        selectedAdditionalValue: '',
        selectedTenantAdditionalInput: '',
        selectedTenantAdditionalValue: '',
      }
    });
  };

  render() {
    const { fields, formErrors } = this.state;

    return (
      <Dialog open={this.props.modal} onClose={this.onModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Nuevo Match de Adicional
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="selected-additional"
            name="selected-additional"
            options={this.state.additionals}
            getOptionLabel={(option) => this.getAdditionalsOptionLabel(option)}
            style={{ width: 500 }}
            value={fields.selectedAdditionalValue}
            onChange={(e, newValue) => this.onAdditionalChange(newValue)}
            inputValue={fields.selectedAdditionalInput}
            onInputChange={(e, newInputValue) => this.onAdditionalInputChange(newInputValue)}
            renderInput={params => (
              <TextField
                error={formErrors.selectedAdditionalInput ? true : false}
                helperText={formErrors.selectedAdditionalInput || ''}
                {...params}
                label="Adicional"
                variant="outlined"
              />
            )}
          />
          <br />
          <Autocomplete
            id="selected-external-additional"
            name="selected-external-additional"
            options={this.state.tenantAdditionals}
            getOptionLabel={(option) => this.getTenantAdditionalsOptionLabel(option)}
            style={{ width: 500 }}
            value={fields.selectedTenantAdditionalValue}
            onChange={(e, newValue) => this.onTenantAdditionalChange(newValue)}
            inputValue={fields.selectedTenantAdditionalInput}
            onInputChange={(e, newInputValue) => this.onLocationInputChange(newInputValue)}
            renderInput={params => (
              <TextField
                error={formErrors.selectedTenantAdditionalInput ? true : false}
                helperText={formErrors.selectedTenantAdditionalInput}
                {...params}
                label="Adicional del tenant"
                variant="outlined"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onModalClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(AdditionalMatchForm);