import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  listModels,
  listCategories,
  getRentlyCategories
} from '../../services/api';

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const props = {
  tenantId: PropTypes.string,
  models: PropTypes.array,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func
};

class ModelMatchForm extends React.Component {
  static propTypes = props;

  constructor(props) {
    super(props);
    this.state = {
      models: [],
      externalModels: [],
      categories: [],
      createAsNewModel: false,
      asNewModelDisabled: false,
      selectedModelInput: '',
      selectedModelValue: null,
      selectedExternalModelInput: '',
      selectedExternalModelValue: null,
      selectedCategoryInput: '',
      selectedCategoryValue: null,
      modal: this.props.modal || false
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal && this.props.modal) {
      const categoriesResp = await getRentlyCategories(this.props.tenantId);
      const modelsResp = await listModels();
      let models, externalModels = [];

      if (categoriesResp.status === 200 && categoriesResp.data)
        externalModels = categoriesResp.data.flatMap(cat => cat.models) || [];

      if (modelsResp.status === 200 && modelsResp.data)
        models = modelsResp.data;

      this.setState({ models, externalModels, loading: false });
    }
  };

  onInputChange = (event) => {
    const { name, value } = event.target;
    const modified = { ...this.state, ...{ [name]: value } };

    debugger;
    this.setState(modified);
  };

  onCheckBoxChange = async (event) => {
    const { checked } = event.target;
    let categories = [];

    if (checked && !this.categories) {
      const categoriesResp = await listCategories()
      if (categoriesResp.status === 200 && categoriesResp.data) {
        categories = categoriesResp.data || [];
      }
    }

    this.setState({
      createAsNewModel: checked,
      categories,
      selectedCategoryInput: '',
      selectedCategoryValue: null
    });
  };

  getOptionLabel = (model) => {
    if (!model) return '';
    return `[${model.id}] ${model.name} - ${model.category.name} (${model.sipp}) - Puertas: ${model.doors} | Pasajeros: ${model.passengers} | Aire: ${model.hasAirCondition} | Caja: ${model.transmitionType === 0 ? "Manual" : "Automatica"} | Maletas Grandes: ${model.bigLuggage} | Maletas Chicas: ${model.smallLuggage}`;
  }

  getOptionLabelExternal = (model) => {
    if (!model) return '';
    return `[${model.id}] ${model.name} - ${model.category.name} (${model.sipp}) - Puertas: ${model.doors} | Pasajeros: ${model.passengers} | Aire: ${model.airConditioner} | Caja: ${model.gearbox} | Maletas Grandes: ${model.bigLuggage} | Maletas Chicas: ${model.smallLuggage}`;
  }

  onModelChange = (newValue) => {
    this.setState({
      selectedModelValue: newValue,
      createAsNewModel: newValue === null ? true : false,
      asNewModelDisabled: newValue === null ? false : true,
      selectedCategoryInput: '',
      selectedCategoryValue: null
    });
  };

  submit = () => {
    if (!this.isFormDataValid()) return;

    const {
      selectedModelValue,
      selectedExternalModelValue,
      selectedCategoryValue,
      createAsNewModel
    } = this.state;

    this.clearComponentState();
    this.props.onModalSubmit(
      selectedModelValue ? selectedModelValue.id : null,
      selectedExternalModelValue,
      selectedCategoryValue,
      createAsNewModel
    );
  };

  isFormDataValid = () => {
    const { selectedModelValue, selectedExternalModelValue, selectedCategoryValue, createAsNewModel } = this.state;

    if ((!selectedModelValue || !selectedExternalModelValue) && createAsNewModel === false) {
      alert('Seleccione ambos modelos');
      return false;
    }

    if (selectedModelValue === null && createAsNewModel === true && selectedCategoryValue === null) {
      alert('Seleccione a qué categoria se debe agregar el nuevo modelo');
      return false;
    }

    return true;
  }

  onModalClose = () => {
    this.clearComponentState();
    this.props.onModalClose();
  }

  clearComponentState = () => {
    this.setState({
      models: [],
      externalModels: [],
      createAsNewModel: false,
      asNewModelDisabled: false,
      selectedModelInput: '',
      selectedModelValue: null,
      selectedExternalModelInput: '',
      selectedExternalModelValue: null
    });
  }

  render() {
    return (
      <Dialog open={this.props.modal} onClose={this.onModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Nuevo Match de modelo
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="selectedModelId"
            name="selectedModelId"
            options={this.state.models}
            getOptionLabel={(option) => this.getOptionLabel(option)}
            style={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label="Modelo" variant="outlined" />}
            value={this.state.selectedModelValue}
            onChange={(e, newValue) => this.onModelChange(newValue)}
            inputValue={this.state.selectedModelInput}
            onInputChange={(e, newInputValue) => this.setState({ selectedModelInput: newInputValue })}
          />
          <br />
          <Autocomplete
            id="selected-external-model-id"
            name="selected-external-model-id"
            options={this.state.externalModels}
            getOptionLabel={(option) => this.getOptionLabelExternal(option)}
            style={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label="Modelo Externo" variant="outlined" />}
            value={this.state.selectedExternalModelValue}
            onChange={(e, newValue) => this.setState({ selectedExternalModelValue: newValue })}
            inputValue={this.state.selectedExternalModelInput}
            onInputChange={(e, newInputValue) => this.setState({ selectedExternalModelInput: newInputValue })}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                name="createAsNewModel"
                color="primary"
                checked={this.state.createAsNewModel}
                onChange={e => this.onCheckBoxChange(e)}
                disabled={this.state.asNewModelDisabled}
              />
            }
            label="Crear como modelo nuevo"
          />
          <br />
          {this.state.createAsNewModel && <Autocomplete
            id="selected-category-id"
            name="selected-category-id"
            options={this.state.categories}
            getOptionLabel={(option) => option.name}
            style={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label="Agregar en categoría" variant="outlined" />}
            value={this.state.selectedCategoryValue}
            onChange={(e, newValue) => this.setState({ selectedCategoryValue: newValue })}
            inputValue={this.state.selectedCategoryInput}
            onInputChange={(e, newInputValue) => this.setState({ selectedCategoryInput: newInputValue })}
          />}

        </DialogContent>
        <DialogActions>
          <Button onClick={this.onModalClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(ModelMatchForm);