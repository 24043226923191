import React from 'react';
import { TranslatedComponent, NumberDisplay } from 'components/';
import { withTranslation } from 'react-i18next';
import { Paper, Table, TableBody, TableRow, TableCell, TableContainer, TableHead } from '@material-ui/core';

import { PropTypes } from 'prop-types';

const props = {
	selectedCar: PropTypes.object
};

class BookingPriceItem extends TranslatedComponent {
	static propTypes = props;

  render() {
		const { selectedCar } = this.props;
		if (!selectedCar) return null;

		const lowercaseKeys = obj =>
			Object.keys(obj).reduce((acc, key) => {
				acc[key.toLowerCase()] = obj[key];
				return acc;
  	}, {});

		const selectedItem = lowercaseKeys(selectedCar);

		return (
			<TableContainer component={Paper}>
				<Table className='' aria-label="custom pagination table">
					<TableHead>
						<TableRow>
							<TableCell>Descripción</TableCell>
							<TableCell>Cantidad</TableCell>
							<TableCell>Precio U.</TableCell>
							<TableCell>Total</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{selectedItem && selectedItem.priceitems.map((item, index) => (
							<TableRow key={index}>
								<TableCell>{item.description || item.Description}</TableCell>
								<TableCell>{item.quantity || item.Quantity}</TableCell>
								<TableCell><NumberDisplay value={item.unitPrice || item.UnitPrice} showPrefix currency={selectedItem.currency} /></TableCell>
								<TableCell><NumberDisplay value={item.price || item.Price} showPrefix currency={selectedItem.currency} /></TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell colSpan={3}><b>Total</b></TableCell>
							<TableCell><b><NumberDisplay value={selectedItem.price} showPrefix currency={selectedItem.currency} /></b></TableCell>
							</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		)
  }
};

export default withTranslation()(BookingPriceItem);
