import {
  GLOBAL_SET_LIST_PLACES,
  GLOBAL_SET_LIST_CURRENCIES,
  GLOBAL_SET_LIST_CATEGORIES,
  GLOBAL_SET_LIST_PROMOTIONS,
  GLOBAL_SET_LIST_ADDITIONALS,
  GLOBAL_SET_SPINNER,
  GLOBAL_SET_LIST_LANGUAGES,
  GLOBAL_SET_LIST_LANGUAGES_INFO,
  GLOBAL_SET_LIST_HOLIDAYS,
  GLOBAL_SET_LIST_SCHEDULES,
  GLOBAL_SET_LIST_COUNTRIES
} from '../utils/types';

const INITIAL_STATE = {
  listPlaces: null,
  listCategories: null,
  listPromotions: null,
  listAdditionals: null,
  listSchedules: null,
  listHolidays: null,
  listCurrencies: null,
  listLanguages: null,
  listLanguagesInfo: null,
  listCountries: null,
  currentLanguage: 'es',
  spinner: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_SET_LIST_PLACES: {
      return {
        ...state,
        listPlaces: action.payload,
      };
    }
    case GLOBAL_SET_LIST_CURRENCIES: {
      return {
        ...state,
        listCurrencies: action.payload,
      };
    }
    case GLOBAL_SET_LIST_CATEGORIES: {
      return {
        ...state,
        listCategories: action.payload,
      };
    }
    case GLOBAL_SET_LIST_PROMOTIONS: {
      return {
        ...state,
        listPromotions: action.payload,
      };
    }
    case GLOBAL_SET_LIST_ADDITIONALS: {
      return {
        ...state,
        listAdditionals: action.payload,
      };
    }
    case GLOBAL_SET_LIST_HOLIDAYS: {
      return {
        ...state,
        listHolidays: action.payload,
      };
    }
    case GLOBAL_SET_LIST_SCHEDULES: {
      return {
        ...state,
        listSchedules: action.payload,
      };
    }
    case GLOBAL_SET_LIST_LANGUAGES: {
      return {
        ...state,
        listLanguages: action.payload,
      };
    }
    case GLOBAL_SET_LIST_LANGUAGES_INFO: {
      return {
        ...state,
        listLanguagesInfo: action.payload,
      };
    }
    case GLOBAL_SET_LIST_COUNTRIES: {
      return {
        ...state,
        listCountries: action.payload,
      };
    }
    case GLOBAL_SET_SPINNER: {
      return {
        ...state,
        spinner: action.payload,
      };
    }

    default:
      return state;
  }
};
