import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { listTenants, getBrokerSuppliers } from '../../services/api';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

const props = {
  brokerId: PropTypes.string,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func
};

class SupplierMatchForm extends React.Component {
  static propTypes = props;

  constructor(props) {
    super(props);
    this.state = {
      tenants: [],
			currentTenantIds: [],
      modal: this.props.modal || false,
			checkAllTenants: false,
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal) {
      const tenantsResp = await listTenants();
			const brokerMatchesResp = await getBrokerSuppliers(this.props.brokerId);
      let tenants, currentTenantIds = [];

      if (tenantsResp.status === 200 && tenantsResp.data)
        tenants = tenantsResp.data;

			if (brokerMatchesResp.status === 200 && brokerMatchesResp.data)
			  currentTenantIds = brokerMatchesResp.data.flatMap(match => match.supplierId);

      this.setState({ tenants, currentTenantIds });
    }
  };

	onToggleAllTenants = (event) => {
		const { checked } = event.target;
		let currentTenantIds = [];

		if (checked)
			currentTenantIds = this.state.tenants.flatMap(tenant => tenant.id);
		
		this.setState({ currentTenantIds, checkAllTenants: checked });
	}

  onCheckBoxChange = (event, tenantId) => {
    const { checked } = event.target;
		let currentTenantIds = this.state.currentTenantIds;

		if (checked) {
			currentTenantIds.push(tenantId);
		} else {
			const index = currentTenantIds.indexOf(tenantId);
			if (index > -1) currentTenantIds.splice(index, 1);
		}

		this.setState({ currentTenantIds, checkAllTenants: false });
  };

  submit = () => {
    this.props.onModalSubmit(this.state.currentTenantIds);
		this.clearComponentState();
  };

  onModalClose = () => {
    this.props.onModalClose();
		this.clearComponentState();
  };

  clearComponentState = () => {
    this.setState({
			checkAllTenants: false,
    });
  };

	isAlreadyChecked = (tenantId) => {
		return this.state.currentTenantIds.find(id => id === tenantId) ? true : false;
	}

  render() {
    return (
      <Dialog open={this.props.modal} onClose={this.onModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Nuevo Match de Tenants
        </DialogTitle>
        <DialogContent>
					<FormControlLabel
						key={`checkbox-select-all-tenants`}
						control={
							<Checkbox
								name="createAsNewModel"
								color="primary"
								checked={this.state.checkAllTenants}
								onChange={e => this.onToggleAllTenants(e)}
							/>
						}
						label={'Seleccionar todos'}
					/>
					<br />
					{this.state.tenants.map(tenant => 
						<FormControlLabel
							key={`checkbox-${tenant.id}`}
							control={
								<Checkbox
									name="createAsNewModel"
									color="primary"
									checked={this.isAlreadyChecked(tenant.id)}
									onChange={e => this.onCheckBoxChange(e, tenant.id)}
								/>
							}
							label={tenant.name}
						/>
					)}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onModalClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(SupplierMatchForm);