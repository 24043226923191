import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { props, defaultProps } from './RangeDatePicker.props';
import { START_DATE } from 'react-dates/constants';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class RangeDatePicker extends Component {
  static propTypes = props;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate || null,
      endDate: props.endDate || null,
      focusedInput: props.focusedInput || START_DATE,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return { startDate: nextProps.startDate, endDate: nextProps.endDate };
  };

  handleDatesChange = (startDate, endDate) => {
    const { onDatesChange } = this.props;
    onDatesChange(startDate, endDate);
    this.setState({ startDate, endDate });
  };

  handleFocusChange = focusedInput => {
    const { onFocusChange } = this.props;
    onFocusChange(focusedInput);
    this.setState({ focusedInput });
  };
  
  handleWeekDays = (day) => {
    const { i18n } = this.props;
    const lang = i18n.language.toUpperCase();

    if(lang === 'ES' || lang === 'ES-AR' || lang=== 'ES-CL' || lang === 'ES-MX') {
      day._locale._weekdaysMin = ['DOM','LUN','MAR','MIE','JUE','VIE','SAB'];
    } else if(lang === 'PT') {
      day._locale._weekdaysMin = ['DOM','SEG','TER','QUA','QUI','SEX','SAB'];
    } else {
      day._locale._weekdaysMin = ['SUN','MON','TUE','WED','THU','FRI','SAT']; 
    } 
    return (day.format('D'));
  }

  handleMonthsName = (month) => {
    const { i18n } = this.props;
    const lang = i18n.language.toUpperCase();

    if(lang === 'ES' || lang === 'ES-AR' || lang=== 'ES-CL' || lang === 'ES-MX') {
      month._locale._months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    } else if(lang === 'PT') {
      month._locale._months = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    } else {
      month._locale._months = ['January','February','March','April','May','June','July', 'August', 'September', 'October', 'November', 'December'];
    }
    return (month.format('MMMM'));
  }

  render() {
    const {
      startDateId,
      endDateId,
      showDefaultInputIcon,
      inputIconPosition,
      block,
      noBorder,
      settings,
      isDayBlocked,
    } = this.props;

    return (
      <DateRangePicker
        startDateId={startDateId}
        startDate={this.state.startDate}
        endDateId={endDateId}
        endDate={this.state.endDate}
        displayFormat="DD/MM/YYYY"
        renderDayContents={this.handleWeekDays}
        renderMonthText={this.handleMonthsName}
        onDatesChange={({ startDate, endDate }) =>
          this.handleDatesChange(startDate, endDate)
        }
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.handleFocusChange(focusedInput)}
        showDefaultInputIcon={showDefaultInputIcon}
        inputIconPosition={inputIconPosition}
        block={block}
        noBorder={noBorder}
        isDayBlocked={(day) => isDayBlocked(day, this.state.focusedInput)}
        readOnly
        {...settings}
      />
    );
  }
};

const mapStateToProps = ({ global }) => ({
  langs: global.listLanguages,
});

const RangeDatePickerComp = withTranslation()(RangeDatePicker);

export default connect(mapStateToProps)(RangeDatePickerComp);
