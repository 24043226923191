import React from 'react';
import { props } from './Title.props';
import { StyledTitle } from './Title.styled';
import { tt } from '../../utils/translationHelper';
import { useTranslation } from 'react-i18next';

const headerTypes = {
  h1: (text, attr) => <h1 {...attr}>{text}</h1>,
  h2: (text, attr) => <h2 {...attr}>{text}</h2>,
  h3: text => <h3>{text}</h3>,
  h4: text => <h4>{text}</h4>,
  h5: text => <h5>{text}</h5>,
  h6: text => <h6>{text}</h6>,
};

const Title = ({
  type = 'h1',
  text,
  bold = true,
  italic = true,
  className,
  color,
  weight,
  fontSize,
  margin,
  lineHeight,
  noMedia,
  showTooltip = false,
  forceTranslate = false
}) => {
  const { t } = useTranslation();
  const attr = {};

  if (showTooltip) {
    attr.title = tt(text, t);
  }
  const translatedText = tt(text, t, forceTranslate);
  if (!translatedText)
    return null;
  return (
    <StyledTitle
      bold={bold}
      italic={italic}
      className={className}
      color={color}
      weight={weight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      noMedia={noMedia}
      margin={margin}
    >
      {headerTypes[type](translatedText, attr)}
    </StyledTitle>
  );
};

Title.propTypes = props;

export default Title;
