import React, { Component } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { props, defaultProps } from './DatePicker.props';
import { SingleDatePicker } from 'react-dates';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date || moment(),
      focused: false,
    };
  }

  handleDateChange(date) {
    const { onDateChange } = this.props;
    onDateChange(date);
    this.setState({ date });
  }

  handleFocusChange(focused) {
    const { onFocusChange } = this.props;
    onFocusChange(focused);
    this.setState({ focused });
  }

  render() {
    const {
      id,
      height,
      numberOfMonths,
      showDefaultInputIcon,
      block,
      noBorder,
      verticalHeight,
      settings,
    } = this.props;
    return (
      <SingleDatePicker
        date={this.state.date}
        focused={this.state.focused}
        id={id}
        inputIconPosition="after"
        numberOfMonths={numberOfMonths}
        isOutsideRange={() => false}
        displayFormat="DD/MM/YYYY"
        onDateChange={date => this.handleDateChange(date)}
        onFocusChange={({ focused }) => this.handleFocusChange(focused)}
        showDefaultInputIcon={showDefaultInputIcon}
        block={block}
        noBorder={noBorder}
        verticalHeight={height || verticalHeight}
        {...settings}
      />
    );
  }
}

DatePicker.propTypes = props;
DatePicker.defaultProps = defaultProps;
export default DatePicker;
