const BaseTheme = {
  name: 'base',
  colors: {
    bg: '#fff',
    searchBg: '#EFEFEF',
    primary: '#000',
    secondary: '#FCDE2F',
    third: '#fff',
    mobileHeader: '#272727',
    buttonPrimaryBg: '#FCDE2F',
    buttonPrimaryBgHover: '#f7b500',
    buttonPrimaryBorder: '#ffd255',
    buttonPrimaryColor: '#000',
    disabled: '#a19fa4',
    alert: '#ff0000',
    hightlight: '#DDDDDD'
  },
  font: {
    colors: {
      primary: '#fff',
      secondary: '#000',
      third: '#FFBB3C',
    },
  },
  screens: {
    xs: '475.98px',
    sm: '575.98px',
    md: '767.98px',
    lg: '1080px',
    xl: '1280px',
    xxl: '1480px',
    xxxl: '2300px',
  },
  typography: {
    medium: 14
  },
  background: {
    primary: 'white'
  }
};

export default BaseTheme;
