// Styled components for LoginPage
// Use this file to create all the styled components you need
// in order to properly style component your new component
//
import styled from 'styled-components';

export const StyledLoginPageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .login-paper-container {
    padding: 50px;
    width: 400px;
    margin: 10% auto;
  }

  .login-btn {
    width: 100%;
    margin-top: 20px;
  }

  .actions {
    font-size: 18px;
    text-align: center;
    color: ${(props) => props.theme.colors.secondary};
    margin-top: 4rem;

    p {
      font-size: 1rem;
    }
    a {
      font-family: ${(props) => props.theme.typography.medium};
      transition: color 0.2s;
      &:hover {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
    .small {
      font-size: 0.9rem;
      font-size: 0.9rem;
      background: ${(props) => props.theme.background.primary};
      padding: 8px 15px;
      display: inline-block;
      border-radius: 7px;
      color: ${(props) => props.theme.colors.light};
    }
    .small a {
      color: ${(props) => props.theme.colors.light} !important;
      &:hover {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }

  .forgot-password {
    font-weight: bold;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.secondary} !important;
    float: right;
    font-size: 0.9rem;
    transition: color 0.2s;
    &:hover {
      color: ${(props) => props.theme.colors.primary} !important;
    }
  }
  .form-check {
    padding-left: 0;
  }
  .remember-checkbox {
    display: inline-block;
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    background-color: ${(props) => props.theme.colors.secondary};
    border-color: ${(props) => props.theme.colors.secondary};
  }
  @media (max-width: 768px) {
    margin-top: 3rem;
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`;
