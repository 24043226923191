import React, { useContext } from 'react';

export const SettingsContext = React.createContext({});

export default function withSettingsContext(Component) {
    return class extends React.Component {
        static contextType = SettingsContext;

        render() {
            return <Component {...this.props} settings={this.context} />;
          }
    };
}

export function useSettingsContext(){
  return useContext(SettingsContext);
}
