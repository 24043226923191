import React from 'react';
import { props, defaultProps } from './CheckboxInput.props';
import { StyledInput } from './CheckboxInput.styled';
import { withTheme } from 'styled-components';

class CheckboxInput extends React.Component {
  static propTypes = props;
  static defaultProps = defaultProps;
  state = { stateChecked: this.props.checked || false };

  changeState = () => {
    const { onChange } = this.props;
    const status = !this.state.stateChecked;
    this.setState({ stateChecked: status });
    return onChange(status);
  };

  componentWillReceiveProps(prevProps, prevState) {
    this.setState({ ...prevState, stateChecked: prevProps.checked });
  }

  render() {
    const {
      inputClass,
      className,
      color,
      fontSize,
      error,
      label,
      text,
      disable,
    } = this.props;
    const { stateChecked } = this.state;
    return (
      <StyledInput
        color={color}
        fontSize={fontSize}
        className={className}
        error={error}
        disabled={disable}
      >
        {label && <div className="label">{label}</div>}
        <div className="input-wrapper">
          <label>
            <input
              checked={stateChecked}
              className={`${inputClass || ''} checkbox`}
              type="checkbox"
              onChange={this.changeState}
            />
            <span className={`${disable ? 'disabled' : 'check-mark'}`} />
            {text}
          </label>
        </div>
        {error && <div className="error">{error}</div>}
      </StyledInput>
    );
  }
}
export default withTheme(CheckboxInput);
