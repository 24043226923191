import React, { Component } from 'react';
import { StyledAccessDenied } from './AccessDenied.styled';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Title } from 'components';

class AccessDeniedPage extends Component {
  static propTypes = {
    settings: PropTypes.shape({ title: PropTypes.string }),
    t: PropTypes.func,
  };

  componentDidMount() {
    const {
      t,
    } = this.props;

    document.title = `${t('PageAccessDenied')} | Rently API Admin`;
  }

  render() {
    const { t } = this.props;

    return (
      <StyledAccessDenied>
        <div id="accessdenied">
          <div className="accessdenied">
            <div className="accessdenied-403">
              <div />
              <h1>403</h1>
            </div>
            <Title type="h2" text={t('PageAccessDenied')} />
            <p>{t('PageAccessDeniedDescription')}</p>
          </div>
        </div>
      </StyledAccessDenied>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(withTranslation()(AccessDeniedPage));
