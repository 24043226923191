// Global types
export const GLOBAL_SET_LIST_PLACES = 'global_set_places';
export const GLOBAL_SET_LIST_CATEGORIES = 'global_set_categories';
export const GLOBAL_SET_LIST_PROMOTIONS = 'global_set_promotions';
export const GLOBAL_SET_LIST_ADDITIONALS = 'global_set_additionals';
export const GLOBAL_SET_LIST_HOLIDAYS = 'global_set_holidays';
export const GLOBAL_SET_LIST_SCHEDULES = 'global_set_schedules';
export const GLOBAL_SET_LIST_LANGUAGES = 'global_set_languages';
export const GLOBAL_SET_LIST_CURRENCIES = 'global_set_currencies';
export const GLOBAL_SET_LIST_LANGUAGES_INFO = 'global_set_languages_info';
export const GLOBAL_SET_LIST_COUNTRIES = 'global_set_countries';

export const GLOBAL_SET_SPINNER = 'global_set_spinner';

// Site Configuration types

export const SITE_CONFIGURATION_SET_SETTINGS =
  'site_configuration_set_settings';

// Search Cars types
export const SEARCH_CARS_SET_CARS = 'search_cars_set_cars';
export const SEARCH_CARS_SET_PARAMS = 'search_cars_set_params';
export const SEARCH_CARS_SET_CHECKOUT = 'search_cars_set_checkout';

// Bookings
export const BOOKING_SET_BOOK = 'booking_set_book';
export const BOOKING_SET_ADDITIONALS = 'booking_set_additionals';
export const SET_BOOKING_PAY_INSTALLMENT = 'booking_set_pay_installment';

// Infractions
export const SET_INFRACTION = 'set_infraction';
export const SET_INFRACTION_PAY_INSTALLMENT = 'infraction_set_pay_installment';