import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import styled from 'styled-components';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import './i18n';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const StyledApp = styled.div`
  * {
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    width: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root{
  }
`;

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'd72a3b30-4fa3-4c88-973d-24a3193901c8'
    /* ...Other Configuration Options... */
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const rootApp = (
  <StyledApp>
    <Suspense fallback="<h1>Loading...</h1>">
      <App />
    </Suspense>
  </StyledApp>
);

ReactDOM.render(rootApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
