import style from 'styled-components';

export const StyledContainer = style.div`

.MuiFormLabel-root, .MuiFormControlLabel-label {
    font-size: 0.8rem;
    white-space: nowrap;
} 

.MuiFormControlLabel-root {
    width: 95%;
}

.HalfInputsContainer{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    .MuiTextField-root{
        width: 48%;
    }
}
`;
