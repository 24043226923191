import React from 'react';
import { props, defaultProps } from './SelectInput.props';
import { StyledInput } from './SelectInput.styled';

const createOption = (option, key, selectedValue) => {
  if (option.value === selectedValue) {
    return (
      <option value={option.value} key={key} selected>
        {option.text}
      </option>
    );
  } else {
    return (
      <option value={option.value} key={key} >
        {option.text}
      </option>
    );
  }
};

const SelectInput = ({
  wrapperClass,
  inputClass,
  color,
  onChange,
  borderColor,
  fontSize,
  iconColor,
  placeholder,
  icon,
  error,
  options,
  label,
  selected,
  disabled,
  name,
  props
}) => (
    <StyledInput
      color={color}
      fontSize={fontSize}
      className={wrapperClass}
      borderColor={borderColor}
      iconColor={iconColor}
      error={error}
      options={options}
      disabled={disabled}
    >
      {label && <label htmlFor={name} className="label">{label}</label>}
      <div className="input-wrapper mb-3">
        {icon && <span className={icon} />}
        <select
          className={inputClass}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          value={selected || 0}
          id={name}
          name={name}
          {...props}
        >
          <option value={null}>Seleccionar</option>
          {options.map((option, key) => {
            if (option.options) {
              return (
                <optgroup label={option.title} value={option.title} key={key}>
                  {option.options.map(opt => {
                    return (
                      <option value={opt} key={opt}>
                        {opt}
                      </option>
                    );
                  })}
                </optgroup>
              );
            }
            if (option.text && option.value) {
              return createOption(option, key, selected);
            }
            return (
              <option value={option} key={key}>
                {option}
              </option>
            );
          })}
        </select>
      </div>
      {error && <div className="error">{error}</div>}
    </StyledInput>
  );

SelectInput.propTypes = props;
SelectInput.defaultProps = defaultProps;
export default SelectInput;
