import style from 'styled-components';

export const AvailabilityRowStyled = style.div`
    .car-description{
      display: flex;
      padding: 20px;
    }

    .separator {
      color: #6f6f6f;
    }

    .car-name {
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .car-image{
      border: 1px solid #CBCBCB;
      height: fit-content;
      text-align: center;
      flex: 0 0 33%;
      max-width: 33%;
    }

    .car-image img{
      max-height: 164px;
    }

    .car-category{
      font-weight: 600;
    }

    .car-price {
      margin: auto;
      text-align: center;

      p.rent-days {
        font-weight: 400;
      }

      p.rent-price {
        margin-top: 5px !important;
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        white-space: nowrap;

        .long-value{
          font-size: 24px;
        }

        span {
          font-size: 28px;
        }
      }

      button {
        width: 100%;
      }

      .second-btn {
        color: #000;
        margin-top: 1em;
        font-size: 13px;
        white-space: pre-wrap;

        span {
          padding-left: 3px;
        }
      }
    }

    .details {
      width: 100%;

      .details-item {
        text-align: center;
        margin-right: 5px;
        display: inline-block;
        width: 10.5%;

        img{
          border: 1px solid #CCCCCC;
          padding: 5px;
          border-radius: 7px;
          margin-bottom: 5px;
          width: 100%;
          max-width: 40px;
        }

        span {
          margin-top: 5px;
          font-weight: 400;
          font-size: .75em;
        }
      }
    }

    .car-mobile-details {
      display: none;
    }

    .partial-availability-mobile {
      display: none;
    }

    .disabled-link {
      cursor: default;
      font-style: italic;
    }

    button {
      span {
        font-weight: 500;
        font-size: 0.875rem !important;
      }
    }
`;
