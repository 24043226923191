import React from 'react';
import { TranslatedComponent, NumberDisplay } from 'components/';
import { withTranslation } from 'react-i18next';
import { AvailabilityRowStyled } from './AvailabilityRow.styled';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PropTypes } from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { createTestBooking } from '../../services/api';
import { toast } from 'react-toastify';
class AvailabilityRow extends TranslatedComponent {
  static propTypes = {
    row: PropTypes.object,
    type: PropTypes.string,
    priceItemsOpenModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async createTestBooking() {
    const { brokerId, commercialAgreement, row } = this.props;

    this.setState({ loading: true });
    try {
      await createTestBooking(row, brokerId, commercialAgreement);
      toast.success('Reserva creada con exito!');
    } catch (error) {
      toast.error(
        `No se pudo crear la reserva: [${error.response.data.errorCode}] ${error.response.data.errorMessage}`,
      );
      // eslint-disable-next-line no-console
      console.log(error.response.data);
    }
    this.setState({ loading: false });
  }

  render() {
    const { t, row, type, priceItemsOpenModal } = this.props;
    const { loading } = this.state;

    if (!row) return;

    const info = {
      model:
        type === 'iata'
          ? row.model
          : {
              ...row.car.model,
              ...{
                franchise: row.franchise,
                franchiseDamage: row.franchiseDamage,
                franchiseRollover: row.franchiseRollover,
                franchiseTheft: row.franchiseTheft,
              },
            },
      brand: type === 'iata' ? row.model.brand : row.car.model.brand.name,
      category: row.category.name,
    };

    return (
      <AvailabilityRowStyled className="row mr-0 ml-0 d-flex search-item-row car-item pt-4 pb-4 pl-4 pr-4">
        <div className="col-lg-9 col-md-12 car-description">
          <div className="car-image mr-4">
            <img
              className="img-fluid"
              src={info.model.imagePath}
              alt={info.model.name}
            />
          </div>
          <div className="car-info">
            <h2 className="car-name">
              {info.brand} {info.model.name}
            </h2>
            <p>
              <b>{t('category')}:</b> {row.category.name}
              <span className="mr-2 ml-2 separator md-hidden">|</span>
              <span className="tachometer md-hidden">
                <i className="fas fa-tachometer-alt mr-1"></i>
                <b>{t('KmIlimitados')}:</b>{' '}
                {row.ilimitedKm === true ? 'Si' : 'No'}
              </span>
            </p>
            <div className="car-details md-hidden">
              <p className="franchise mt-0">
                <i
                  className="fas fa-info-circle"
                  data-toggle="tooltip"
                  title={t('franchiseHelpText')}
                />
                <strong> {t('franquicia')} </strong>
                <NumberDisplay value={info.model.franchise} />
              </p>
              <p className="franchise mt-0">
                <strong> Franquicia Vuelco </strong>
                <NumberDisplay value={info.model.franchiseRollover} />
              </p>
              <p className="franchise mt-0">
                <strong> Franquicia Robo </strong>
                <NumberDisplay value={info.model.franchiseTheft} />
              </p>
              <p className="franchise mt-0">
                <strong> Franquicia Daño </strong>
                <NumberDisplay value={info.model.franchiseDamage} />
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 car-price">
          {row.supplier && row.supplier.logoPath ? (
            <img
              src={row.supplier.logoPath}
              alt={row.supplier.name}
              width={70}
              height={30}
            />
          ) : (
            ''
          )}
          <p className="rent-days mb-0 mt-2">
            {t('alquilerPor')}
            <span>
              {' '}
              <strong>{row.totalDaysString}</strong>{' '}
            </span>
          </p>
          <p className="rent-price mt-0 mb-0">
            <NumberDisplay
              value={row.price}
              showPrefix
              currency={row.currency}
            />
            <HelpOutlineIcon
              className="help-icon"
              style={{ margin: '0px 0px 8px 4px', cursor: 'pointer' }}
              onClick={() => priceItemsOpenModal(row)}
            />
            <br />
            <Button
              variant="outlined"
              style={{ height: '55px', marginLeft: '10px' }}
              color="primary"
              onClick={() => this.createTestBooking()}
              disabled={loading}
            >
              {loading && <CircularProgress size={14} />}&nbsp;Reserva Test
            </Button>
          </p>
        </div>
      </AvailabilityRowStyled>
    );
  }
}

export default withTranslation()(AvailabilityRow);
