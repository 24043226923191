import axios from 'axios';
import mockApi from 'utils/mockApi'; // uncomment this to try mocks
import AuthService from 'auth/AuthService';

class Fetcher {
  /**
   * @desc Metodo nativo de React.js
   *
   * @doc https://reactjs.org/docs/react-component.html#constructor
   * @return { void }
   */
  constructor() {
    this.apiInstance =
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_USE_MOCK === 'true'
        ? mockApi
        : axios; // uncomment this to try mocks
    this.config = {
      /**
       *
       *   HEADER WAS HARDCODED TO TEST PURPOSES *
       * */

      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    };
    // headers
    this.dispatch = false;
  }

  /**
   *  add headers
   * @param { Object } headersArray
   * @return { Headers }
   */
  addHeaders(newHeaders = {}) {
    //
    let { headersArray } = this;

    // add headers
    Object.keys(headersArray).length > 0 &&
      headersArray.forEach((prop, value) => {
        headersArray.append(prop, value);
      });
  }

  /**
   * perform  get request
   * @param { String } endpoint
   * @return { Promise }
   */
  async get(endpoint, needsAuthentication = false) {
    return await this.apiInstance.get(
      endpoint,
      this.getConfig(needsAuthentication),
    );
  }

  /**
   * perform a post
   * @param  { String } endpoint
   * @param  { Object } data
   * @return { Promise }
   */
  async post(endpoint, payload, needsAuthentication, configuration) {
    const { data } = await this.apiInstance.post(
      endpoint,
      payload,
      configuration || this.getConfig(needsAuthentication),
    );
    return data;
  }

  /**
   * perform a put
   * @param  { String } endpoint
   * @param  { Object } data
   * @return { Promise }
   */
  async put(endpoint, payload, needsAuthentication) {
    const { data } = await this.apiInstance.put(
      endpoint,
      payload,
      this.getConfig(needsAuthentication),
    );
    return data;
  }

  /**
   * perform a remove request
   * @param { String } endpoint
   * @return { Promise }
   */
  async remove(endpoint, needsAuthentication) {
    let { data } = await this.apiInstance.delete(
      endpoint,
      this.getConfig(needsAuthentication),
    );
    return data;
  }

  getConfig(addAuthHeader) {
    let config = { ...this.config };

    if (addAuthHeader && AuthService.isAuthenticated()) {
      config.headers[
        'authorization'
      ] = `bearer ${AuthService.getAccessToken()}`;
    }

    return config;
  }
}

export default Fetcher;
