import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  listTenants,
  getRentlyPlaces,
  listLocations,
} from '../../services/api';
import * as yup from 'yup';

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormGroup,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const props = {
  tenantId: PropTypes.string,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func,
};

class PlaceForm extends React.Component {
  static propTypes = props;

  constructor(props) {
    const { t } = props;
    super(props);

    this.state = {
      tenants: [],
      places: [],
      locations: [],
      modal: this.props.modal || false,
      fields: {
        selectedTenantInput: '',
        selectedTenantValue: null,
        selectedPlaceInput: '',
        selectedPlaceValue: null,
        selectedLocationInput: '',
        selectedLocationValue: null,
        branchOfficeId: '',
        email: '',
        phone: '',
        longitude: '',
        latitude: '',
        address: '',
        address2: '',
        selectedTypeInput: '',
        selectedTypeValue: null,
        selectedServiceTypeInput: '',
        selectedServiceTypeValue: null,
      },
      formErrors: {
        selectedPlaceInput: '',
        selectedLocationInput: '',
        email: '',
        phone: '',
        longitude: '',
        latitude: '',
        address: '',
        address2: '',
        selectedTypeInput: '',
        selectedServiceTypeInput: '',
      },
    };

    this.formSchema = yup.object().shape({
      selectedPlaceInput: yup
        .string()
        .required(t('inputIsRequired', { field: 'Lugar' })),
      selectedLocationInput: yup
        .string()
        .required(t('inputIsRequired', { field: 'Locacion' })),
      email: yup
        .string()
        .email(t('validEmail'))
        .required(t('inputIsRequired', { field: t('mail') })),
      phone: yup.string().required(t('inputIsRequired', { field: t('phone') })),
      latitude: yup
        .number()
        .typeError('Latitud inválida')
        .required(t('inputIsRequired', { field: 'Latitud' })),
      longitude: yup
        .number()
        .typeError('Longitud inválida')
        .required(t('inputIsRequired', { field: 'Longitud' })),
      address: yup
        .string()
        .required(t('inputIsRequired', { field: 'Dirección' })),
      address2: yup
        .string()
        .required(t('inputIsRequired', { field: 'Dirección 2' })),
      selectedTypeInput: yup
        .string()
        .required(t('inputIsRequired', { field: 'Tipo de lugar' })),
      selectedServiceTypeInput: yup
        .string()
        .required(t('inputIsRequired', { field: 'Tipo de servicio' })),
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal && this.props.modal) {
      const tenantsResp = await listTenants();
      const placesResp = await getRentlyPlaces(this.props.tenantId);
      const locationsResp = await listLocations();
      let tenants,
        places,
        locations = [];

      if (tenantsResp.status === 200 && tenantsResp.data) {
        const allTenants = tenantsResp.data;
        tenants = allTenants.filter(t => t.id !== Number(this.props.tenantId));
      }

      if (placesResp.status === 200 && placesResp.data)
        places = placesResp.data;

      if (locationsResp.status === 200 && locationsResp.data)
        locations = locationsResp.data;

      this.setState({ tenants, places, locations, loading: false });
    }
  }

  getTenantsOptionLabel = option => {
    return `[${option.id}] ${option.name}`;
  };

  getPlacesOptionLabel = option => {
    return `[${option.id}] ${option.branchOfficeIATACode || ''} - ${
      option.name
    } (${option.city}, ${option.country})`;
  };

  getLocationsOptionLabel = option => {
    return `${option.iataCode} - ${option.name} - ${option.city} (${option.countryCode})`;
  };

  onTenantChange = async option => {
    let places = [];

    const tenantId = option ? option.id : this.props.tenantId;
    const placesResp = await getRentlyPlaces(tenantId);

    if (placesResp.status === 200 && placesResp.data) places = placesResp.data;

    const fields = {
      ...this.state.fields,
      ...{
        selectedTenantValue: option,
        selectedPlaceInput: '',
        selectedPlaceValue: null,
      },
    };

    this.setState({ places, fields });
  };

  onTenantInputChange = newValue => {
    const fields = {
      ...this.state.fields,
      ...{ selectedTenantInput: newValue },
    };
    this.setState({ fields });
  };

  onPlaceChange = option => {
    const fields = {
      ...this.state.fields,
      ...{
        selectedPlaceValue: option,
        internalId: option ? option.id : '',
        branchOfficeId: option ? option.branchOfficeId : '',
        longitude: option ? option.longitude : '',
        latitude: option ? option.latitude : '',
        address: option ? option.address : '',
        phone: option ? option.phone : '',
      },
    };

    const formErrors = {
      ...this.state.formErrors,
      ...{
        selectedPlaceInput: '',
        selectedLocationInput: '',
        email: '',
        phone: '',
        longitude: '',
        latitude: '',
        address: '',
        address2: '',
        selectedTypeInput: '',
        selectedServiceTypeInput: '',
      },
    };

    this.setState({ fields, formErrors });
  };

  onPlaceInputChange = newValue => {
    const fields = {
      ...this.state.fields,
      ...{ selectedPlaceInput: newValue },
    };
    const formErrors = {
      ...this.state.formErrors,
      ...{ selectedPlaceInput: '' },
    };
    this.setState({ fields, formErrors });
  };

  onLocationChange = option => {
    const fields = {
      ...this.state.fields,
      ...{ selectedLocationValue: option },
    };
    this.setState({ fields });
  };

  onLocationInputChange = newValue => {
    const fields = {
      ...this.state.fields,
      ...{ selectedLocationInput: newValue },
    };
    const formErrors = {
      ...this.state.formErrors,
      ...{ selectedLocationInput: '' },
    };
    this.setState({ fields, formErrors });
  };

  onTypeChange = option => {
    const fields = { ...this.state.fields, ...{ selectedTypeValue: option } };
    this.setState({ fields });
  };

  onTypeInputChange = newValue => {
    const fields = { ...this.state.fields, ...{ selectedTypeInput: newValue } };
    const formErrors = {
      ...this.state.formErrors,
      ...{ selectedTypeInput: '' },
    };
    this.setState({ fields, formErrors });
  };

  onServiceTypeChange = option => {
    const fields = {
      ...this.state.fields,
      ...{ selectedServiceTypeValue: option },
    };
    this.setState({ fields });
  };

  onServiceTypeInputChange = newValue => {
    const fields = {
      ...this.state.fields,
      ...{ selectedServiceTypeInput: newValue },
    };
    const formErrors = {
      ...this.state.formErrors,
      ...{ selectedServiceTypeInput: '' },
    };
    this.setState({ fields, formErrors });
  };

  onInputChange = event => {
    const { name, value } = event.target;
    const fields = { ...this.state.fields, ...{ [name]: value } };
    const formErrors = { ...this.state.formErrors, ...{ [name]: '' } };
    this.setState({ fields, formErrors });
  };

  getPlaceTypes = () => {
    return [
      { id: '', name: 'Seleccionar' },
      { id: 0, name: 'Office' },
      { id: 1, name: 'Airport' },
      { id: 2, name: 'Port' },
      { id: 3, name: 'BusTerminal' },
    ];
  };

  getServiceTypes = () => {
    return [
      { id: '', name: 'Seleccionar' },
      { id: 0, name: 'Walking' },
      { id: 1, name: 'MeetAndGreet' },
      { id: 2, name: 'Charter' },
    ];
  };

  submit = () => {
    this.formSchema
      .validate(this.state.fields, { abortEarly: false })
      .then(valid => {
        const { fields } = this.state;
        const newLocationObj = {
          tenantId: Number(this.props.tenantId),
          useTenantId: fields.selectedTenantValue
            ? fields.selectedTenantValue.id
            : null,
          locationId: fields.selectedLocationValue.iataCode,
          email: fields.email,
          phone: fields.phone,
          internalId: fields.internalId,
          branchOfficeId: fields.branchOfficeId,
          longitude: fields.longitude,
          latitude: fields.latitude,
          address: fields.address,
          address2: fields.address2,
          type: fields.selectedTypeValue.id,
          serviceType: fields.selectedServiceTypeValue.id,
        };

        this.clearComponentState();
        this.props.onModalSubmit(newLocationObj);
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        this.setState(prevState => ({
          ...prevState,
          formErrors,
          saving: false,
        }));
      });
  };

  onModalClose = () => {
    this.clearComponentState();
    this.props.onModalClose();
  };

  clearComponentState = () => {
    this.setState({
      tenants: [],
      places: [],
      locations: [],
      fields: {
        selectedTenantInput: '',
        selectedTenantValue: null,
        selectedPlaceInput: '',
        selectedPlaceValue: null,
        selectedLocationInput: '',
        selectedLocationValue: null,
        internalId: null,
        branchOfficeId: '',
        email: '',
        phone: '',
        longitude: '',
        latitude: '',
        address: '',
        address2: '',
        selectedTypeInput: '',
        selectedTypeValue: null,
        selectedServiceTypeInput: '',
        selectedServiceTypeValue: null,
      },
      formErrors: {
        selectedPlaceInput: '',
        selectedLocationInput: '',
        email: '',
        phone: '',
        longitude: '',
        latitude: '',
        address: '',
        address2: '',
        selectedTypeInput: '',
        selectedServiceTypeInput: '',
      },
    });
  };

  render() {
    const { fields, formErrors } = this.state;

    return (
      <Dialog
        open={this.props.modal}
        onClose={this.onModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nuevo Lugar</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="selectedTenantId"
            name="selectedTenantId"
            options={this.state.tenants}
            getOptionLabel={option => this.getTenantsOptionLabel(option)}
            style={{ width: 500 }}
            renderInput={params => (
              <TextField
                {...params}
                label="Cambiar a Tenant"
                variant="outlined"
              />
            )}
            value={fields.selectedTenantValue}
            onChange={(e, newValue) => this.onTenantChange(newValue)}
            inputValue={fields.selectedTenantInput}
            onInputChange={(e, newInputValue) => {
              this.onTenantInputChange(newInputValue);
            }}
          />
          <br />
          <Autocomplete
            id="selectedPlaceId"
            name="selectedPlaceId"
            options={this.state.places}
            getOptionLabel={option => this.getPlacesOptionLabel(option)}
            style={{ width: 500 }}
            value={fields.selectedPlaceValue}
            onChange={(e, newValue) => this.onPlaceChange(newValue)}
            inputValue={fields.selectedPlaceInput}
            onInputChange={(e, newInputValue) =>
              this.onPlaceInputChange(newInputValue)
            }
            renderInput={params => (
              <TextField
                error={formErrors.selectedPlaceInput ? true : false}
                helperText={formErrors.selectedPlaceInput || ''}
                {...params}
                label="Lugar"
                variant="outlined"
              />
            )}
          />
          <br />
          <Autocomplete
            id="selectedLocationId"
            name="selectedLocationId"
            options={this.state.locations}
            getOptionLabel={option => this.getLocationsOptionLabel(option)}
            style={{ width: 500 }}
            value={fields.selectedLocationValue}
            onChange={(e, newValue) => this.onLocationChange(newValue)}
            inputValue={fields.selectedLocationInput}
            onInputChange={(e, newInputValue) =>
              this.onLocationInputChange(newInputValue)
            }
            renderInput={params => (
              <TextField
                error={formErrors.selectedLocationInput ? true : false}
                helperText={formErrors.selectedLocationInput}
                {...params}
                label="Location"
                variant="outlined"
              />
            )}
          />
          <br />
          {fields.selectedPlaceValue && (
            <FormGroup>
              <TextField
                margin="dense"
                id="branchOfficeId"
                name="branchOfficeId"
                label={'Branch office ID'}
                type="text"
                fullWidth
                value={fields.branchOfficeId}
                disabled
              />
              <TextField
                margin="dense"
                id="email"
                name="email"
                label={'Email'}
                type="text"
                fullWidth
                value={fields.email}
                onChange={e => this.onInputChange(e)}
                helperText={formErrors.email}
                error={formErrors.email ? true : false}
              />
              <TextField
                margin="dense"
                id="phone"
                name="phone"
                label={'Teléfono'}
                type="text"
                fullWidth
                value={fields.phone}
                onChange={e => this.onInputChange(e)}
                helperText={formErrors.phone}
                error={formErrors.phone ? true : false}
              />
              <TextField
                margin="dense"
                id="longitude"
                name="longitude"
                label={'Longitude'}
                type="text"
                fullWidth
                value={fields.longitude}
                onChange={e => this.onInputChange(e)}
                helperText={formErrors.longitude}
                error={formErrors.longitude ? true : false}
              />
              <TextField
                margin="dense"
                id="latitude"
                name="latitude"
                label={'Latitude'}
                type="text"
                fullWidth
                value={fields.latitude}
                onChange={e => this.onInputChange(e)}
                helperText={formErrors.latitude}
                error={formErrors.latitude ? true : false}
              />
              <TextField
                margin="dense"
                id="address"
                name="address"
                label={'Dirección'}
                type="text"
                fullWidth
                value={fields.address}
                onChange={e => this.onInputChange(e)}
                helperText={formErrors.address}
                error={formErrors.address ? true : false}
              />
              <TextField
                margin="dense"
                id="address2"
                name="address2"
                label={'Dirección 2'}
                type="text"
                fullWidth
                value={fields.address2}
                onChange={e => this.onInputChange(e)}
                helperText={formErrors.address2}
                error={formErrors.address2 ? true : false}
              />
              <br />
              <Autocomplete
                id="type"
                name="type"
                options={this.getPlaceTypes()}
                getOptionLabel={option => option.name}
                style={{ width: 500 }}
                value={fields.selectedTypeValue}
                onChange={(e, newValue) => this.onTypeChange(newValue)}
                inputValue={fields.selectedTypeInput}
                onInputChange={(e, newInputValue) =>
                  this.onTypeInputChange(newInputValue)
                }
                renderInput={params => (
                  <TextField
                    error={formErrors.selectedTypeInput ? true : false}
                    helperText={formErrors.selectedTypeInput}
                    {...params}
                    label="Tipo de lugar"
                    variant="outlined"
                  />
                )}
              />
              <br />
              <Autocomplete
                id="serviceType"
                name="serviceType"
                options={this.getServiceTypes()}
                getOptionLabel={option => option.name}
                style={{ width: 500 }}
                value={fields.selectedServiceTypeValue}
                onChange={(e, newValue) => this.onServiceTypeChange(newValue)}
                inputValue={fields.selectedServiceTypeInput}
                onInputChange={(e, newInputValue) =>
                  this.onServiceTypeInputChange(newInputValue)
                }
                renderInput={params => (
                  <TextField
                    error={formErrors.selectedServiceTypeInput ? true : false}
                    helperText={formErrors.selectedServiceTypeInput}
                    {...params}
                    label="Tipo de servicio"
                    variant="outlined"
                  />
                )}
              />
            </FormGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onModalClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(PlaceForm);
