import siteJSON from './jsonMocks/site-config';
import searchJSON from './jsonMocks/search-response';
import checkoutJSON from './jsonMocks/checkout-response';
import listCategoriesJSON from './jsonMocks/list-categories';
import listHolidaysJSON from './jsonMocks/list-holidays';
import getSchedulesJSON from './jsonMocks/get-attention-schedules';
import listPlacesJSON from './jsonMocks/list-places';
import listCountriesJSON from './jsonMocks/list-countries';
import promotionsJSON from './jsonMocks/promotions';
import additionalsJSON from './jsonMocks/list-additionals';
import priceJSON from './jsonMocks/price';
import bookJSON from './jsonMocks/book';
import langJSON from './jsonMocks/list-languages-info';
import currenciesJSON from './jsonMocks/list-currencies';

const SiteConfiguration = () => siteJSON.SiteConfiguration;
const search = () => searchJSON;
const mockCheckout = () => checkoutJSON;
const categories = () => listCategoriesJSON;
const holidays = () => listHolidaysJSON;
const attentionschedule = () => getSchedulesJSON;
const countries = () => listCountriesJSON;
const languages = () => ['es-AR', 'en-US', 'pt'];
const places = () => listPlacesJSON;
const promotions = () => promotionsJSON;
const additionals = () => additionalsJSON;
const price = () => priceJSON;
const book = () => bookJSON;
const webcancelbooking = () => { return { bookingId: 123 } };
const contact = () => { return { bookingId: 123 } };
const validHosts = () => { return ['localhost'] };
const currencies = () => currenciesJSON;
const languagesInfo = () => langJSON;

const getEndpoints = {
  SiteConfiguration,
  search,
  mockCheckout,
  categories,
  holidays,
  attentionschedule,
  countries,
  languages,
  places,
  promotions,
  additionals,
  price,
  book,
  currencies,
  "languages-info": languagesInfo,
  "valid-hosts": validHosts,
  "additionals-price": () => { }
};

const postEndpoints = {
  book,
  webcancelbooking,
  contact
};

export default class MockApi {
  static async get(endpoint) {
    const uri = endpoint.split('/');
    const len = uri[3].indexOf("?");
    return { data: getEndpoints[uri[3].substring(0, len > 0 ? len : uri[3].lenght)]() };
  }

  static async post(endpoint, data, config) {
    const uri = endpoint.split('/');
    const len = uri[3].indexOf("?");
    return { data: postEndpoints[uri[3].substring(0, len > 0 ? len : uri[3].lenght)]() };
  }
}
