import style from 'styled-components';

export const StyledAccessDenied = style.section`
  #notfound {width: 100%;}

		#notfound .notfound {
	    text-align: center;
	    margin: auto;
	    margin-top: 7%;
	    margin-bottom: 6%;
		}

		.notfound {
		  max-width: 560px;
		  width: 100%;
		  text-align: center;
		  line-height: 1.4;
		}

		.notfound .notfound-404 {
		  position: relative;
		  width: 180px;
		  height: 180px;
		  margin: 50px auto;
		}

		.notfound .notfound-404>div:first-child {
		  position: absolute;
		  left: 0;
		  right: 0;
		  top: 0;
		  bottom: 0;
		  background: #ffa200;
		  -webkit-transform: rotate(45deg);
		      -ms-transform: rotate(45deg);
		          transform: rotate(45deg);
		  border: 5px dashed #000;
		  border-radius: 5px;
		}

		.notfound .notfound-404>div:first-child:before {
		  content: '';
		  position: absolute;
		  left: -5px;
		  right: -5px;
		  bottom: -5px;
		  top: -5px;
		  -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
		          box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
		  border-radius: 5px;
		}

		.notfound .notfound-404 h1 {
		  font-family: 'Montserrat', sans-serif;
		  color: #000;
		  font-weight: 700;
		  margin: 0;
		  font-size: 90px;
		  position: absolute;
		  top: 50%;
		  -webkit-transform: translate(-50%, -50%);
		      -ms-transform: translate(-50%, -50%);
		          transform: translate(-50%, -50%);
		  left: 50%;
		  text-align: center;
		  height: 40px;
		  line-height: 40px;
		}

		.notfound h2 {
		  font-size: 33px;
		  margin-bottom: 10px;
		}

		.notfound p {
		  font-size: 16px;
		  color: #000;
		  margin-bottom: 30px;
    }
    `;
