import Fetcher from 'utils/fetcher';

const api = new Fetcher();
const apiUrl = process.env.REACT_APP_BACKEND_API;
const apiBaseUrl = process.env.REACT_APP_BACKEND_URI;

export const listTenants = async () => api.get(`${apiUrl}/admin/tenants`, true);
export const getModel = async id =>
  api.get(`${apiUrl}/admin/models/${id}`, true);

export const updateModel = async (modelId, modelInfo) =>
  api.put(`${apiUrl}/admin/models/${modelId}`, modelInfo, true);

export const listAdditionals = async () =>
  api.get(`${apiUrl}/additionals`, true);

export const createAdditional = async additionalInfo =>
  api.post(`${apiUrl}/admin/additionals`, additionalInfo, true);

export const updateAdditional = async (additionalId, additionalInfo) =>
  api.put(`${apiUrl}/admin/additionals/${additionalId}`, additionalInfo, true);

export const getTenantAdditionals = async tenantId =>
  api.get(`${apiUrl}/admin/tenants/${tenantId}/additionals`, true);

export const createTenantAdditionalMatch = async (tenantId, additionalInfo) =>
  api.post(
    `${apiUrl}/admin/tenants/${tenantId}/additionals`,
    additionalInfo,
    true,
  );

export const deleteTenantAdditionalMatch = async (
  tenantId,
  additionalMatchId,
) =>
  api.remove(
    `${apiUrl}/admin/tenants/${tenantId}/additionals/${additionalMatchId}`,
    true,
  );

export const listBookings = async () =>
  api.get(`${apiUrl}/admin/bookings`, true);

export const getBookingPayload = async bookingId =>
  api.get(`${apiBaseUrl}/api/admin/bookings/${bookingId}/payload`, true);

export const refreshBookingPayload = async bookingId =>
  api.post(`${apiBaseUrl}/api/admin/bookings/${bookingId}/update`, null, true);

export const cancelBookingPayload = async bookingId =>
  api.remove(`${apiBaseUrl}/api/booking/${bookingId}`, true);

export const listLocations = async () =>
  api.get(`${apiUrl}/admin/locations`, true);

export const createLocation = async locationInfo =>
  api.post(`${apiUrl}/admin/locations`, locationInfo, true);

export const deleteLocation = async iataCode =>
  api.remove(`${apiUrl}/admin/locations/${iataCode}`, true);

export const listModels = async () => api.get(`${apiUrl}/admin/models`, true);

export const listIatas = async code =>
  api.get(`${apiUrl}/IATA?code=${code}`, true);

export const getTenantPlaces = async tenantId =>
  api.get(`${apiUrl}/admin/tenants/${tenantId}/places`, true);

export const getRentlyPlaces = async tenantId =>
  api.get(`${apiUrl}/admin/tenant/info/${tenantId}/places`, true);

export const createTenantPlace = async (tenantId, placeInfo) =>
  api.post(`${apiUrl}/admin/tenants/${tenantId}/places`, placeInfo, true);

export const deleteTenantPlace = async (tenantId, placeId) =>
  api.remove(`${apiUrl}/admin/tenants/${tenantId}/places/${placeId}`, true);

export const updateTenantPlace = async (tenantId, placeId, place) =>
  api.put(`${apiUrl}/admin/tenants/${tenantId}/places/${placeId}`, place, true);

export const getRentlyAdditionals = async tenantId =>
  api.get(`${apiUrl}/admin/tenant/info/${tenantId}/additional`, true);

export const getTenantModels = async tenantId =>
  api.get(`${apiUrl}/admin/tenants/${tenantId}/models`, true);

export const createTenantModelMatch = async (tenantId, matchInfo) =>
  api.post(`${apiUrl}/admin/tenants/${tenantId}/models`, matchInfo, true);

export const setBrokerSupplierMatch = async matchInfo =>
  api.post(`${apiUrl}/admin/brokers/suppliers`, matchInfo, true);

export const deleteTenantModelMatch = async (tenantId, matchId) =>
  api.remove(`${apiUrl}/admin/tenants/${tenantId}/models/${matchId}`, true);

export const getRentlyCategories = async tenantId =>
  api.get(`${apiUrl}/admin/tenant/info/${tenantId}/categories`, true);

export const listCategories = async () =>
  api.get(`${apiUrl}/admin/categories`, true);

export const listBrokers = async () => api.get(`${apiUrl}/admin/brokers`, true);

export const createBroker = async brokerInfo =>
  api.post(`${apiUrl}/admin/brokers/register`, brokerInfo, true);

export const getBroker = async brokerId =>
  api.get(`${apiUrl}/admin/brokers/${brokerId}`, true);

export const updateBroker = async (brokerId, brokerInfo) =>
  api.put(`${apiUrl}/admin/brokers/${brokerId}`, brokerInfo, true);

export const getBrokerSuppliers = async brokerId =>
  api.get(`${apiUrl}/admin/brokers/${brokerId}/suppliers`, true);

export const getBrokerPlaces = async brokerId =>
  api.get(`${apiUrl}/admin/brokers/${brokerId}/places`, true);

export const getTenant = async tenantId =>
  api.get(`${apiUrl}/admin/tenants/${tenantId}`, true);

export const createTenant = async tenantInfo =>
  api.post(`${apiUrl}/admin/tenants`, tenantInfo, true);

export const updateTenant = async (tenantId, tenantInfo) =>
  api.put(`${apiUrl}/admin/tenants/${tenantId}`, tenantInfo, true);

export const getCommercialAgreements = async (brokerId, placeId) => {
  return api.get(
    `${apiUrl}/admin/brokers/${brokerId}/commercial-agreements?placeId=${placeId}`,
    true,
  );
};

export const getAvailabilityByIata = async (
  brokerId,
  from,
  to,
  pickUp,
  dropOff,
  commercialAgreementCode,
) => {
  let url = `${apiUrl}/availability?brokerId=${brokerId}&from=${from}&to=${to}&DeliveryLocation=${pickUp}&DropoffLocation=${dropOff}`;

  if (
    commercialAgreementCode &&
    commercialAgreementCode !== '' &&
    commercialAgreementCode !== 'None'
  )
    url += `&commercialAgreementCode=${commercialAgreementCode}`;

  return api.get(url, true);
};

export const getAvailabilityByPlace = async (
  brokerId,
  from,
  to,
  pickUp,
  dropOff,
  commercialAgreementCode,
) => {
  let url = `${apiBaseUrl}/v1/api/search?brokerId=${brokerId}&from=${from}&to=${to}&fromPlace=${pickUp}&toPlace=${dropOff}`;

  if (
    commercialAgreementCode &&
    commercialAgreementCode !== '' &&
    commercialAgreementCode !== 'None'
  )
    url += `&commercialAgreementCode=${commercialAgreementCode}`;

  return api.get(url, true);
};

export const createTestBooking = async (
  booking,
  brokerId,
  commercialAgreementCode,
) => {
  let url = `${apiUrl}/booking?brokerId=${brokerId}`;

  const modelId = booking.car ? booking.car.model.id : booking.model.id;

  var saveBooking = {
    Customer: {
      FirstName: 'Rently',
      LastName: 'Test',
      EmailAddress: 'bookings@rentlysoft.com',
      DocumentType: 'Passport',
      DocumentId: 'rently-1234',
      BirthDate: '1985-03-13',
    },
    extra: 'Esta es una reserva de prueba / This is a test booking',
    fromDate: booking.fromDate,
    toDate: booking.toDate,
    deliveryPlace: booking.deliveryPlace.id,
    dropoffPlace: booking.returnPlace.id,
    currency: booking.currency,
    model: modelId,
  };

  if (
    commercialAgreementCode &&
    commercialAgreementCode !== '' &&
    commercialAgreementCode !== 'None'
  )
    saveBooking.CommercialAgreementCode = commercialAgreementCode;

  return api.post(url, saveBooking, true);
};

export const authenticate = async (username, password) => {
  return api.post(
    `${apiBaseUrl}/connect/token`,
    `username=${username}&password=${password}&grant_type=password&client_id=RentlyAPI`,
    false,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};
