import { history } from '../store';
import { authenticate } from 'services/api';
import jwtDecode from 'jwt-decode';

class Auth {

  login(username, password) {
    authenticate(username, password).then((response) => {
      this.handleAuthentication(response);
    })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.debug(err);
      });
  }

  handleAuthentication(authResult, err) {
    if (authResult && authResult.access_token) {
      this.setSession(authResult);
    } else if (err) {
      history.replace('/admin');

      alert(`Error: ${err.error}. Check the console for further details.`);
    }
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  }

  setSession(authResult) {
    const scopes = authResult.scope || this.requestedScopes || '';
    const user = jwtDecode(authResult.access_token);

    if (scopes.indexOf("admin") < 0 && user.role && user.role.indexOf("admin") < 0) {
      history.replace('/accessdenied');
      return;
    }

    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the access token will expire at
    let expiresAt = (authResult.expires_in * 1000) + new Date().getTime();

    localStorage.setItem('access_token', authResult.access_token);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('scopes', JSON.stringify(scopes));
    // navigate to the home route
    history.replace('/admin');
  }

  logout() {
    // Remove tokens and expiry time
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scopes');

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');

    // this.auth0.logout({
    //   return_to: window.location.origin
    // });

    // navigate to the home route
    history.replace('/admin');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  userHasScopes(scopes) {
    const grantedScopes = (JSON.parse(localStorage.getItem('scopes')) || '').split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  }
}

const AuthService = new Auth();

export default AuthService;