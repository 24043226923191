import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';
import * as yup from 'yup';

const props = {
	brokerId: PropTypes.number,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSubmit: PropTypes.func
};

class BrokerForm extends React.Component {
	static propTypes = props;

  constructor(props) {
    const { t } = props;
    super(props);

    this.state = {
      broker: {
        userName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        IATA: '',
        forcePasswordValidation: true
      },
      isLoading: true,
      saving: false,
      modal: this.props.modal,
      formErrors: {
        userName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        IATA: ''
      }
    };

    yup.addMethod(yup.string, "doPasswordsMatch", function(confirmationPassword) {
      return this.test("test-passwords-match", 'Las contraseñas no coinciden', function(confirmationPassword) {
        const { password } = this.parent;
        return password === confirmationPassword;
      });
    });

    this.formSchema = yup.object().shape({
      userName: yup
        .string()
        .required(
          t('inputIsRequired', { field: 'Nombre de usuario' }),
        ),
      email: yup
        .string()
        .email('Email inválido')
        .required(
          t('inputIsRequired', { field: 'Email' }),
        ),
      password: yup
        .string()
        .when("forcePasswordValidation", {
          is: true,
          then: yup.string().required(t('inputIsRequired', { field: 'Contraseña' })),
        }),
      passwordConfirmation: yup
        .string()
        .when("forcePasswordValidation", {
          is: true,
          then: yup.string().doPasswordsMatch().required(t('inputIsRequired', { field: 'Contraseña' })),
        }),
      IATA: yup
        .string()
        .required(
          t('inputIsRequired', { field: 'IATA' }),
        ),
    });
	}

  async componentDidUpdate(prevProps) {
    const {broker} = this.props;

    if (this.props.brokerId === prevProps.brokerId) return;

    if (broker) {
      this.setState(state => ({
        broker:{
          ...state.broker,
          id: broker.id,
          userName: broker.name,
          email: broker.email,
          IATA: broker.iata,
          forcePasswordValidation: false,
        }
      }));
    } else {
      const broker = {
        userName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        IATA: '',
        forcePasswordValidation: true
      }
      this.setState({ broker });
    }
  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    const broker = { ...this.state.broker, ...{ [name]: value } };
    const formErrors = { ...this.state.formErrors, ...{ [name]: '' } }
    this.setState({ broker, formErrors });
  };

  onCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    const broker = { ...this.state.broker, ...{ [name]: checked } };
    this.setState({ broker });
  };

  submit = () => {
    this.setState({ saving: true });

    this.formSchema
      .validate(this.state.broker, { abortEarly: false })
      .then(valid => {
        this.props.onModalSubmit(this.state.broker);
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        this.setState(prevState => ({ ...prevState, formErrors, saving: false }));
      });
  };

  closeModal = () => {
    const broker = { id: '', userName: '', email: '', password: '', IATA: '' };
    const formErrors = { userName: '', email: '', password: '', IATA: '' };
    this.setState(prevState => ({ ...prevState, broker, formErrors }));
    this.props.onModalClose();
  }

	render() {
    const { brokerId } = this.props;
    const { broker, formErrors } = this.state;

		return (
      <Dialog open={this.props.modal} onClose={this.closeModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {this.props.brokerId ? 'Editar Broker' : 'Nuevo Broker'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="userName"
            name="userName"
            label={"Nombre de usuario"}
            type="text"
            fullWidth
            onChange={e => this.onInputChange(e)}
            value={broker.userName}
            helperText={formErrors.userName}
            disabled={!!this.props.brokerId}
            required
          />
          <TextField
            id="email"
            name="email"
            label={"Email"}
            type="text"
            fullWidth
            onChange={e => this.onInputChange(e)}
            value={broker.email}
            helperText={formErrors.email}
          />
          {!brokerId && <>
            <TextField
              margin="dense"
              id="password"
              name="password"
              label={"Contraseña"}
              type="password"
              fullWidth
              onChange={e => this.onInputChange(e)}
              value={broker.password}
              helperText={formErrors.password}
              required
            />
            <TextField
              margin="dense"
              id="passwordConfirmation"
              name="passwordConfirmation"
              label={"Confirmación de contraseña"}
              type="password"
              fullWidth
              onChange={e => this.onInputChange(e)}
              value={broker.passwordConfirmation}
              helperText={formErrors.passwordConfirmation}
              required
            />
          </>}
          <TextField
            margin="dense"
            id="IATA"
            name="IATA"
            label={"IATA"}
            type="text"
            fullWidth
            onChange={e => this.onInputChange(e)}
            value={broker.IATA}
            helperText={formErrors.IATA}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeModal} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={this.submit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
		);
	}
}

export default withTranslation()(BrokerForm);
