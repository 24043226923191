import * as React from 'react';
import { StyledLoginPageWrapper } from './index.styles';
import { createStructuredSelector, createSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import AuthService from 'auth/AuthService';

function LoginPage({ routerParams, push }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginIn, setLoginIn] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    try {
      AuthService.login(email, password)

    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setLoginIn(false);
    }
  };

  return (
    <StyledLoginPageWrapper>
      <Grid>
        <Paper elevation={10} className="login-paper-container">
        <Grid align='center'>
          <h2>Sign In</h2>
        </Grid>
          <form onSubmit={(e) => login(e)}>
            <TextField
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled={loginIn}
              label='Email'
              placeholder='Email'
              fullWidth
              className="mt-2"
              required
            />
            <TextField
              name="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              disabled={loginIn}
              label='Password'
              placeholder='Password'
              type='password'
              fullWidth
              className="mt-2 mb-4"
              required
            />
            <Button 
              className="login-btn"
              type="submit"
              disabled={email.length === 0 || password.length === 0 || loginIn}
              color='primary'
              variant="contained"
              fullWidth
            >
              {t('login')}
            </Button>
          </form>
        </Paper>
      </Grid>
    </StyledLoginPageWrapper>
  );
}

const selectGlobalState = (state) => state;
const makeSelectRouterParams = () =>
  createSelector(selectGlobalState, (appState) => appState.router.location);

const mapStateToProps = createStructuredSelector({
  routerParams: makeSelectRouterParams(),
});

const withConnect = connect(mapStateToProps, { push });

export default compose(withConnect)(LoginPage);
